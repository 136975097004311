<template>
  <Home />
  <About />
  <Projects />
  <!--   <StandBy /> -->
  <Contact />
  <PiePagina />

</template>

<script>
import Home from "../components/home.vue";
import About from "../components/about.vue";
/* import StandBy from "./components/standby.vue"; */
import Contact from "../components/contact.vue";
import PiePagina from "../components/pie_pagina.vue";
import Projects from "../components/projects.vue";
export default {
  components: {
    Home,
    About,
    Contact,
    PiePagina,
    Projects,
  },
};
</script>

<style></style>