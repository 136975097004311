<template>
  <!--  <Home/>
  <About/>
  <Projects/> -->
  <!--   <StandBy /> -->
  <!-- <Contact />
  <PiePagina /> -->
  <router-view></router-view>
</template>

<script>
import Home from "./components/home.vue";
import About from "./components/about.vue";
/* import StandBy from "./components/standby.vue"; */
import Contact from "./components/contact.vue";
import PiePagina from "./components/pie_pagina.vue";
import Projects from "./components/projects.vue";
export default {
  components: {
    Home,
    About,
    Contact,
    PiePagina,
    Projects,
  },
};
</script>

<style>
/* Establece estilos para modo claro */
@media (prefers-color-scheme: dark) {
  body {
    background-color: #ffffff;
    /* Color de fondo claro */
    color: #000000;
    /* Color de texto oscuro */
  }
}
</style>