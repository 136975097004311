<template>
  <br />
  <br />
  <br />
  <br />
  <div class="piePagina">
    <center>
      <h7 style="color: var(--colorSecundario);">CristhianVeAl ;) 🚀</h7>
    </center>
  </div>
</template>

<script>
export default {};
</script>

<style>
.piePagina {
  background-color: #F9F9F9;
  margin: 0px;
  padding: 15px;
}
</style>