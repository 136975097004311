<template>
  <!-- NavBar -->
  <div id ="home" class="navbar-fixed  ">
    <nav style="background-color: var(--colorPrincipal); box-shadow: none">
      <div class="nav-wrapper;">
        <a href="#" class="brand-logo navbar__title"></a>
        <a href="#" data-target="menu-responsive" class="sidenav-trigger">
          <i class="material-icons">menu</i>
        </a>
        <!-- menu pantalla grandes -->
        <ul class="right hide-on-med-and-down">
          <li class="opcionesMenuText"><a href="#">Inicio</a></li>
          <li class="opcionesMenuText"><a href="#about">Sobre mí</a></li>
          <li class="opcionesMenuText"><a href="#projects">Proyectos</a></li>
          <li class="opcionesMenuText"><a href="#contact">Contactos</a></li>
        </ul>
        <!-- menu pantalla grandes -->
      </div>
    </nav>
    <!-- menu amburguessa -->
    <ul
      class=" sidenav "
      id="menu-responsive"
      style="background-color: var(--colorPrincipal)"
    >
      <li>
        <a href="#home" style="color: var(--white); font-size: 16px">Inicio</a>
      </li>
      <li>
        <a style="color: var(--white); font-size: 16px" href="#about">Sobre mí</a>
      </li>
      <li>
        <a style="color: var(--white); font-size: 16px" href="#projects">Proyectos</a>
      </li>
      <li>
        <a style="color: var(--white); font-size: 16px" href="#contact">Contactos</a>
      </li>
    </ul>
    <!-- menu amburguessa -->
  </div>
  <!-- Cuerpo -->
  <div class="background__image">
    <div class="home">
      <div class="container" style="background-color: transparent">
        <div class="row">
          <div class="col m6 s12 transparent">
            <img
              class="circle responsive-img pointerEvents"
              src="/./img/recursos/profile_picture.gif"
              alt="Photo Profile"
            />
          </div>
          <div class="col m6 s12 transparent">
            <h4 class="home__title">¡Hey! 👋</h4>
            <h5 class="home__tittle_name">Soy Cristhian Vera Almeida</h5>
            <h5
              class="home__tittle_cargo typewrite"
              data-period="1000"
              data-type='["Desarrollador Mobile", "Desarrollador Front-end", "Me gusta la electrónica", "Me gusta viajar y hacer amig@s"]'
            ></h5>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Inicio -->
</template>

<script>
export default {};
var TxtType = function (el, toRotate, period) {
  this.toRotate = toRotate;
  this.el = el;
  this.loopNum = 0;
  this.period = parseInt(period, 10) || 2000;
  this.txt = "";
  this.tick();
  this.isDeleting = false;
};

TxtType.prototype.tick = function () {
  var i = this.loopNum % this.toRotate.length;
  var fullTxt = this.toRotate[i];

  if (this.isDeleting) {
    this.txt = fullTxt.substring(0, this.txt.length - 1);
  } else {
    this.txt = fullTxt.substring(0, this.txt.length + 1);
  }

  this.el.innerHTML = '<span class="wrap">' + this.txt + "</span>";

  var that = this;
  var delta = 200 - Math.random() * 100;

  if (this.isDeleting) {
    delta /= 2;
  }

  if (!this.isDeleting && this.txt === fullTxt) {
    delta = this.period;
    this.isDeleting = true;
  } else if (this.isDeleting && this.txt === "") {
    this.isDeleting = false;
    this.loopNum++;
    delta = 500;
  }

  setTimeout(function () {
    that.tick();
  }, delta);
};

window.onload = function () {
  var elements = document.getElementsByClassName("typewrite");
  for (var i = 0; i < elements.length; i++) {
    var toRotate = elements[i].getAttribute("data-type");
    var period = elements[i].getAttribute("data-period");
    if (toRotate) {
      new TxtType(elements[i], JSON.parse(toRotate), period);
    }
  }
  // INJECT CSS
  var css = document.createElement("style");
  css.type = "text/css";
  css.innerHTML = ".typewrite > .wrap { border-right: 0.08em solid #fff}";
  document.body.appendChild(css);
};
</script>

<style>
:root {
  --white: #ffffff;
  --grey: #414141;
  --red: #d62d20;
  --amarillo: #ffa700;
  --blue: #3367d6;
  --colorPrincipal: #fece01;
  --colorSecundario: #5b5b5b;
  --colorTerciario: #e34c26;
  --green: #008744;
  --blueReact: #61dbfb;
}
.navbar__title::before {
  content: "<PORTA";
  color: var(--colorSecundario);
  text-shadow: 0.5px 0.5px var(--colorSecundario);
  font-size: 25px;
}
.navbar__title::after {
  content: "FOLIO/>";
  color: var(--white);
  font-size: 25px;
}
.background__inicio {
  background-color: #ebebeb;
}

.background__image {
  background-image: url("/./img/recursos/fondo_header_dowhile_.png");
  width: 100%;
  height: 100vh;
  background-color: rgb(254, 206, 1, 0.95);
  align-content: center;
  text-align: center;
  background-size: cover;
  background-position: center;
}
.home {
  min-height: 100vh;
  display: flex;
  padding: 30px;
  justify-content: center;
  align-items: center;
  position: relative;
}
.home__title {
  color: var(--colorSecundario);
  font-size: 42px;
  text-shadow: 1px 1px var(--colorSecundario);
}
.home__tittle_name {
  color: var(--white);
  font-size: 40px;
  text-shadow: 1px 1px var(--white);
}
.home__tittle_cargo {
  color: var(--white);
  font-size: 30px;
}
.opcionesMenuText {
  color: var(--white);
  font-size: 16px;
}
.pointerEvents {
  pointer-events: none;
}
</style>