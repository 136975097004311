<template>
  <br />
  <br />
  <div id="about" class="container section scrollspy">
    <div class="row backgroundPrueba2">
      <div class="col m6" style="margin-bottom: 5px">
        <div class="col m12 title">Sobre mí</div>
        <div class="col m12 subtitle">
          Mi nombre es Cristhian Vera Almeida y soy un desarrollador de software especializado en aplicaciones móviles y
          web
        </div>

        <div class="col m12 cuerpoTexto">
          <br />
          Con 4 años de experiencia. Me apasiona crear soluciones innovadoras y escalables utilizando tecnologías como
          Flutter, Vue.js, React y  NodeJS .
          <br />
          <br />
          Me enorgullece desarrollar soluciones eficientes que mejoren la experiencia del
          usuario y cumplan con los objetivos del proyecto.
          <br />
          <br />
          <!--  <center>
            <a class="waves-effect waves-light btn-small green"
              href="/./archives/pdfs/Cristhian Vera Almeida_CV.pdf" target="_blank"><i
                class="material-icons left">visibility</i>Visualizar CV</a>
          </center>  -->
          <center>
            <a class="waves-effect waves-light btn green" href="https://bit.ly/CristhianVeAlCV2023" target="_blank"><i
                class="material-icons left">visibility</i>Visualizar CV</a>
          </center>
        </div>
      </div>
      <div class="col m6 subtitle">
        <br />
        <br />
        <div class="col m12 s12 subtitle">Conocimientos:</div>
        <br />
        <br />
        <div class="row" style="padding-left: 10%">
          <div class="col m3 s3 itemsHabilidades pointerEvents" v-for="(conocimiento, index) in conocimientos"
            :key="index">
            <img height="50" width="50" :src="conocimiento.asset" alt="imagen" align="center" />
            <br />
            <h6 style="text-align: center">{{ conocimiento.titulo }}</h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      conocimientos: [
        {
          titulo: "Flutter",
          asset: "/./img/icons/flutter.svg",
        },
        {
          titulo: "Vue",
          asset: "/./img/icons/vue.svg",
        }, {
          titulo: "React",
          asset: "/./img/icons/react.svg",
        },

        {
          titulo: "JavaScript",
          asset: "/./img/icons/javascript.svg",
        },

        {
          titulo: "PHP",
          asset: "/./img/icons/php.svg",
        },
        {
          titulo: "HTML",
          asset: "/./img/icons/html.svg",
        },
        {
          titulo: "CSS",
          asset: "/./img/icons/css.svg",
        },


        {
          titulo: "Firebase",
          asset: "/./img/icons/firebase.svg",
        },
        {
          titulo: "GitHub",
          asset: "/./img/icons/github.svg",
        },
      ],
    };
  },
};
</script>

<style>
.itemsHabilidades {
  background-color: transparent;
  text-align: center;
  /* padding: 10px; */
  margin: 5px;

  /* IMPORTANTE */
  display: inline-block;
}

.subtitle {
  color: var(--colorSecundario);
  font-size: 22.5px;
  font-weight: bold;
}

.subtitle2 {
  color: var(--colorSecundario);
  font-size: 20px;
  font-weight: bold;
  text-align: justify;
}

.title {
  color: var(--colorSecundario);
  font-size: 45px;
  font-weight: bold;
  text-align: justify;
}

.cuerpoTexto {
  font-size: 18px;
  font-weight: normal;
  text-align: justify;
}
</style>