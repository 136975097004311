<template>
  <div class="parallax-container">
    <div class="parallax" style="filter: blur(0px)">
      <img :src="encabezadoRifa" />
    </div>

    <div class="container parallax-text">
      <h2 id="texto" class="highlight"></h2>
      <h2 class="col s6 m4">
        <h8 class="highlightGrey">1er. Premio:</h8>
        <b class="highlight">CPU Gamer</b>
      </h2>
      <h3 class="col s6 m4">
        <h8 class="highlightGrey">2do. Premio:</h8>
        <b class="highlight"> Monitor Curvo</b>
      </h3>
      <h4 class="col s6 m4">
        <h8 class="highlightGrey">3er. Premio:</h8>
        <b class="highlight">Mesa Gamer</b>
      </h4>
      <h5 class="col s6 m4">
        <h8 class="highlightGrey">4to. Premio:</h8>
        <b class="highlight">Silla Gamer</b>
      </h5>
    </div>
  </div>
  <div class="section white">
    <div class="row container">
      <div v-show="loadingDataRifa" class="progress">
        <div class="indeterminate"></div>
      </div>
      <div v-show="!loadingDataRifa" class="card">
        <!--  <div class="card-content">
      <p>
        I am a very simple card. I am good at containing small bits of
        information. I am convenient because I require little markup to use
        effectively.
      </p>
    </div> -->

        <div class="card-tabs">
          <ul class="tabs tabs-fixed-width">
            <li class="tab">
              <a class="active" style="color: black" href="#comprar"><strong>Registrar</strong></a>
            </li>
         
          </ul>
        </div>
        <div class="white">
          <!-- tab 1 -->
          <div id="comprar" class="container">
            <table class="custom-table centered">
              <thead>
                <tr>
                  <th colspan="10" style="border: 0px">
                    <ul class="pagination center">
                      <li class="waves-effect" @click="prevPage">
                        <a><i class="material-icons">chevron_left</i></a>
                      </li>
                      <li v-for="page in totalPages" :key="page" :class="{ active: currentPage === page }">
                        <a @click="changePage(page)">{{ page }}</a>
                      </li>
                      <li class="waves-effect" @click="nextPage">
                        <a><i class="material-icons">chevron_right</i></a>
                      </li>
                    </ul>
                  </th>
                </tr>
                <tr>
                  <th style="border: 0px" colspan="10">
                    <h6 class="highlight" style="">
                      <strong>NÚMEROS DISPONIBLES</strong>
                    </h6>
                    <div style="margin: 0px; padding: 0px">
                      Elige los números que desees para aumentar tus
                      posibilidades de tener suerte, cada número tiene un valor
                      de
                      <h5 class="highlight">💵$3.00</h5>
                      ¡Buena elección!
                    </div>
                  </th>
                  <!--  <th v-for="header in headers" :key="header">{{ header }}</th> -->
                </tr>
              </thead>
              <tbody>
                <tr style="padding: 0px;  margin: 0px;" v-for="row in paginatedNumbers" :key="row[0]">
                  <td v-for="number in row" :key="number.id" @click="
                    number.ocupado
                      ? showToast(`número ${number.numeroRifa} vendido`)
                      : addCarrito(number)
                    " :style="{
                      background: number.ocupado
                        ? '#AAD4C6'
                        : isInCart(number)
                          ? '#FFEB3B'
                          : '#ffffff',
                      width: '100px',
                      height: '60px',

                    }">

                    <div class="row center-align" style=" ">
                      <div class="col s12 m12">
                        <div class="circleT  s12 m12  " :style="{ background: number.ocupado ? 'white' : '#white' }">
                          {{ number.numeroRifa }} </div>
                      </div>

                      <p class="col  s12 m12 " style="padding: 0px; margin: 0px;"> <strong> {{
                        number.nombres ?? '' }}
                        </strong>
                      </p>

                      <div class="tooltip"> <a class=" col s12 m12 circle responsive-img center btn-flat"
                          style="padding: 0px;  margin: 0px;  " v-if="number.compartido_fb"
                          :href="'https://www.facebook.com/CristhianVeal/posts/pfbid02avscpzCoDcVn4MUFv8RaW7HKBgaHTD5tXs33cDHGwwSRMaAnX4qL8QzT11Ye81RTl'"
                          target="_blank" rel="noopener noreferrer">
                          <i class="material-icons " style="color: #3b5998 " >facebook</i>
                        </a>
                        <span class="tooltiptext">El compró un número y al compartir el post en facebook ganó este número. ¿Que esperas para ganar?</span>
                      </div>



                      <!--       <img v-show="number?.ocupado" draggable="false" :src="number?.iconReserva" alt=""
                        class="circle responsive-img col s12 m12  scale-transition"> -->
                      <!-- <p class="col s12 m12" style="padding: 0px; margin: 0px;"> <strong> {{ number.nombres }} </strong> 
                      </p>-->
                      <!-- Icono de Facebook -->

                    </div>

                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr v-show="this.carrito.length != 0" style="border: 0px">
                  <th style="border: 0px; text-align: center" colspan="10">
                    <a href="#modal1" target="_blank" class="waves-effect waves-light btn pulse modal-trigger"><i
                        class="material-icons left">send </i>Comprar
                      números</a>
                  </th>
                </tr>

                <tr style="border: 0px">
                  <th style="border: 0px; text-align: center" colspan="5">
                    <button @click="prevPage" class="btn">
                      <i class="material-icons">chevron_left</i>
                    </button>
                  </th>

                  <th style="border: 0px; text-align: center" colspan="5">
                    <button @click="nextPage" class="btn">
                      <i class="material-icons">chevron_right</i>
                    </button>
                  </th>
                </tr>

                <tr style="border: 0px">
                  <th style="border: 0px; text-align: center" colspan="4">
                    Números seleccionados
                  </th>
                  <td colspan="1" style="background: #ffeb3b; border: 0px"></td>
                  <th style="border: 0px; text-align: center" colspan="4">
                    No disponibles
                  </th>
                  <td colspan="1" style="background: #aad4c6; border: 0px"></td>
                </tr>
              </tfoot>
            </table>
          </div>

        </div>
      </div>
    </div>
  </div>

  <div v-show="!loadingDataRifa" class="parallax-container">
    <div class="parallax">
      <img style="filter: blur(1.5px)" :src="footerRifa" />
    </div>
    <div class="container parallax-text" style="">
      <h5 style="color: black; text-align: center">
        <b class="highlight" style="color: black">¡Participa en nuestra rifa y ayúdanos a alcanzar nuestro objetivo!</b>
      </h5>
      <h5 style="color: black; text-align: center">
        No hay fecha límite, el sorteo se realizará una vez se vendan el 80% de
        los números números disponibles. ¡Compra tu número ahora y sé parte de
        esta increíble oportunidad!
        <a :href="getWhatsAppLink()" target="_blank">Comprar</a>
      </h5>
      <h4 style="color: black; text-align: center">
        Sortea en
        <a href="https://www.facebook.com/cristhianveal" target="_blank" rel="noopener noreferrer"><b class="blue"
            style="color: white"> Facebook</b><b class="red" style="color: white">Live</b></a>
      </h4>
      <h6 style="color: black; text-align: center">
        <a style="text-align: center" href="https://www.facebook.com/cristhianveal"
          target="__blank">https://www.facebook.com/cristhianveal</a>
      </h6>
    </div>
  </div>

  <div v-show="this.carrito.length != 0" class="fixed-action-btn">
    <a id="menu" class="btn-floating pulse btn-large modal-trigger waves-light waves-effect" href="#modal1">
      <i class="large material-icons" style="color: white">send</i>
    </a>

    <!-- Modal Structure -->
    <div id="modal1" class="modal bottom-sheet">
      <div class="modal-content">
        <h4>
          Reservar números de rifas: <b> {{ getSelectedNumbers() }}.</b>
        </h4>
        <p>Gracias por apoyar con tu compra de los números seleccionados.</p>
        <p>
          Para registrarlo, por favor haz clic en el siguiente
          <a :href="getWhatsAppLink()" target="_blank">enlace</a> para
          redireccionarlo a mi WhatsApp y así ayudarte con la separación.
        </p>
      </div>
      <div class="modal-footer">
        <!-- Enlace para redireccionar a WhatsApp -->
        <a :href="getWhatsAppLink()" target="_blank" class="modal-close waves-effect waves-green btn-flat"
          style="background: #ffeb3b">
          <i class="large material-icons">shopping_cart</i> Reservar Números
        </a>
        <h5 href="#!" class="modal-close waves-effect waves-green btn-flat">
          Reservar otros
        </h5>
      </div>
    </div>
  </div>

  <!-- Tap Target Structure -->
  <!-- <div class="tap-target" data-target="menu">
    <div class="tap-target-content">
      <h5>¡Haz clic aquí para más información!</h5>
      <p>Este es un ejemplo de un elemento de destino táctil.</p>
    </div>
  </div> -->
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      // numbers: Array.from({ length: 1000 }, (_, i) => i + 1),
      numbers: [],
      loadingDataRifa: false,
      codigoReserva: "",
      carrito: [],
      endpointRifa: "https://griesgos.portoviejo.gob.ec/apis/rifa/rifa.php",
      endpointBuscarReserva: "https://griesgos.portoviejo.gob.ec/apis/rifa/reservaPorCodigo.php",
      infoReserva: {},
      currentPage: 1,
      perPage: 100,
      headers: Array.from({ length: 10 }, (_, i) => `Número ${i + 1}`),
      encabezadoRifa: "/./img/recursos/rifa/encabezado_rifa.svg",
      footerRifa: "/./img/recursos/rifa/footer_rifa.svg",
      CPUAsset: "/./img/recursos/rifa/cpu.svg",
      infoCPUAsset: "/./img/recursos/rifa/infoCPU.svg",
      MonitorAsset: "/./img/recursos/rifa/monitor.svg",
      infoMonitorAsset: "/./img/recursos/rifa/infoMonitor.svg",
      MesaAsset: "/./img/recursos/rifa/mesa.svg",
      infoMesaAsset: "/./img/recursos/rifa/infoMesa.svg",
      SillaAsset: "/./img/recursos/rifa/silla.svg",
      infoSillaAsset: "/./img/recursos/rifa/infoSilla.svg",
      chicoGamerAsset: "/./img/recursos/rifa/chicoGamer.svg",
      text: "RIFA Geek 🧑‍💻",
      speed: 100,
      i: 0,
    };
  },
  computed: {
    paginatedNumbers() {
      const startIndex = (this.currentPage - 1) * this.perPage;
      return this.numbers
        .slice(startIndex, startIndex + this.perPage)
        .reduce(
          (rows, key, index) =>
            (index % 10 == 0
              ? rows.push([key])
              : rows[rows.length - 1].push(key)) && rows,
          []
        );
    },
    totalPages() {
      return Math.ceil(this.numbers.length / this.perPage);
    },
  },
  mounted() {
    // Llamar al método para cargar los datos cuando el componente se monte
    this.fetchNumbers();
    this.typeWriter();
  },
  methods: {
    infoEnviar() {
      // Inicializar el elemento de destino táctil
      const elem = document.querySelector(".tap-target");
      const instance = M.TapTarget.init(elem);
      instance.open();
    },
    async fetchNumbers(loadingGeneral) {
      try {
        let numerosRifas = [];
        if (!loadingGeneral) {
          this.loadingDataRifa = true;
        }
        const response = await axios.get(this.endpointRifa);
        this.loadingDataRifa = false;
        if (response.data.status === "ok" && response.data.code === "200") {
          const data = response.data.data;
          const numerosRifas = data.map((item) => ({
            id: item.id,
            numeroRifa: item.numero.toString().padStart(2, "0"),
            nombres: item.nombres,
            ocupado: item.ocupado === 1 ? true : false,
            compartido_fb: item.compartido_fb === 1 ? true : false,
            //iconReserva: 'https://xsgames.co/randomusers/avatar.php?g=pixel'
            //iconReserva: `https://robohash.org/stefan-${item.nombres}`
            iconReserva: `https://ui-avatars.com/api/?name=${item.nombres}`
          }));
          this.numbers = numerosRifas;
        } else {
          throw new Error(response.data.message);
        }
      } catch (error) {
        this.loadingDataRifa = false;
        alert(
          error +
          "Si el error persiste, favor contactar al administrador Cristhian Vera, 0978630130 avisando por vía whatsapp 🙏🙏🙏"
        );
      }
    },
    async buscarCodigoReserva() {
      try {
        if (this.codigoReserva != "" && this.codigoReserva.length > 0) {
          this.infoReserva = {}
          let infoReservaTemp = {};
          //let codigoReserva = "Jrobles"
          const response = await axios.get(this.endpointBuscarReserva + `?codigoReserva=${this.codigoReserva?.trim()}`);


          if (response.data.status === "ok" && response.data.code === "200") {
            const data = response.data.data;
            if (data.numeros != 0) {
              infoReservaTemp = {
                nombres: data.nombres,
                numeros: data.numeros,
                codigo_reserva: data.codigo_reserva,
                telefono: data.telefono,
                compartido_fb: data.compartido_fb,
                observacion: data.observacion,
                numeroFB: data.numeroFB,
              }
              this.infoReserva = infoReservaTemp;
            } else {
              this.showToast("No se encontraron números reservados con el código de reserva proporcionado. Si crees que ha ocurrido un error, verifica tu código o contáctanos para obtener ayuda.")
            }


          } else {
            throw new Error(response.data.message);
          }
        } else {
          this.showToast("Ingrese su código de reserva.");
        }

      } catch (error) {

        alert(
          error +
          "Si el error persiste, favor contactar al administrador Cristhian Vera, 0978630130 avisando por vía whatsapp 🙏🙏🙏"
        );
      }
    },
    getSelectedNumbers() {
      return this.carrito
        .map((item) => item.numeroRifa)
        .sort((a, b) => a - b) // Ordena los números en orden ascendente
        .join(", ");
    },
    getWhatsAppLink(custoMSG) {
      const selectedNumbers = this.getSelectedNumbers();
      let message = `¡Hola! Estoy interesado en comprar los siguientes números de rifas: ${selectedNumbers}. Te saluda, SusNombres.`;
      if (custoMSG) {
        message = custoMSG
      }
      const whatsappNumber = "593978630130"; // Reemplaza 'TU_NUMERO' con tu número de WhatsApp
      return `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(
        message
      )}`;
    },
    clearCarrito() { },
    showToast(mensaje) {

      M.Toast.dismissAll();



      var toastHTML = `<span>${mensaje}</span><button class="btn-flat toast-action">OK</button>`;
      M.toast({ html: toastHTML });
    },
    async addCarrito(number) {
      try {
        M.Toast.dismissAll();
        // await this.fetchNumbers(true);
        /* if (this.carrito.length == 0) {
          this.infoEnviar();
        } */
        const index = this.carrito.findIndex((item) => item.id === number.id);
        if (index !== -1) {
          // Si el número ya está en el carrito, quitarlo
          this.carrito.splice(index, 1);
        } else {
          // Si el número no está en el carrito, agregarlo
          this.carrito.push(number);
        }
      } catch (error) {
        alert("error: add carrito" + error);
      }
    },
    isInCart(number) {
      // Verificar si el número está en el carrito
      return this.carrito.some((item) => item.id === number.id);
    },
    changePage(page) {
      this.currentPage = page;
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    typeWriter() {
      if (this.i < this.text.length) {
        document.getElementById("texto").innerHTML += this.text.charAt(this.i);
        this.i++;
        setTimeout(this.typeWriter, this.speed);
      }
    },
  },
};
document.addEventListener("DOMContentLoaded", function () {
  var elems = document.querySelectorAll(".fixed-action-btn");
});



</script>

<style>
.custom-table th,
td {
  border-color: #ffde59 !important;
  border: 1px solid #ffde59;
  background-color: white;
}

.parallax-text {
  position: absolute;
  top: 45%;
  left: 47%;
  transform: translate(-50%, -50%);
  color: white;
}

.highlight {
  background-color: #ffd700;
  /* Cambia el color de fondo según tus preferencias */
  padding: 0 5px;
  /* Ajusta el espacio alrededor del texto según tus preferencias */
  display: inline-block;
  animation: blink-caret 0.75s step-end infinite;
}

.highlightGrey {
  background-color: #aad4c6;
  /* Cambia el color de fondo según tus preferencias */
  padding: 0 5px;
  color: rgb(36, 34, 34);
  font-weight: bold;
  /* Ajusta el espacio alrededor del texto según tus preferencias */
  display: inline-block;
}

.card-tabs.green .tabs .tab a.active {
  border-bottom: 2px solid green;
  /* Cambia el color a verde */
}

.circleT {
  width: 20px;
  height: 20px;
  border-radius: 25%;
  background-color: #ffffff;
  /* Color de fondo del círculo */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  /* Tamaño del carácter */
  color: #414141;
  /* Color del carácter */
  text-align: center;
  align-content: center;

}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted #3b5998 
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #3b5998 ;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
</style>