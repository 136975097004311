import { createRouter, createWebHistory } from 'vue-router'
import rifa from '../rifa/rifa.vue';
import formulario from '../rifa/formulario.vue';
import App from '../App.vue';
import aboutMe from '../me/aboutMe.vue';


export const routes = [
    {
        path: '/',
        name: 'home',
        component: aboutMe
    },
    {
        path: '/cv',
        name: 'aboutMe',
        component: aboutMe
    },
    /* {
        path: '/rifaGeek',
        name: 'rifaGeek',
        component: rifa
    }, */  
   /*    {
        path: '/hacke',
        name: 'hacke',
        component: formulario
    }, */
    // Ruta comodín para manejar rutas incorrectas
    {
        path: '/:pathMatch(.*)*',
        name: 'notFound',
        component: aboutMe
    }
    // otras rutas aquí
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;