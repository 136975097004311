<template>
  <br />
  <br />
  <br />
  <br />
  <br />
  <div id="projects" class="container section scrollspy">
    <h5 class="title">Proyectos</h5>
    <h5 class="subtitle">
      Aquí puedes ver los proyectos en los que he trabajado.
    </h5>
      <br />

    <div class="row">
      <div class="">
        <!--  <div class="col m1">  -->
        <div
          class="card col m4 hoverable"
          style="margin: 5px; width: 255px; height: 390px; padding-top :3px"
          v-for="(proyecto, index) in proyectos"
          :key="index"
        >
          <div class="card-image waves-effect waves-block waves-light">
            <img class="activator" :src="proyecto.url_image" />
          </div>
          <div class="card-content">
            <span class="card-title activator grey-text text-darken-4"
              >{{ proyecto.nombreProyecto
              }}<i class="material-icons right">more_vert</i></span
            >
            <p>
              <a :href="proyecto.url_sitioWeb" target="_blank">Sitio Web</a>
            </p>
          </div>
          <div class="card-reveal">
            <span class="card-title grey-text text-darken-4"
              >{{ proyecto.nombreProyecto
              }}<i class="material-icons right">close</i></span
            >
            <p>
              {{ proyecto.descripcion }}
            </p>
            <div class="card-action">
              <a :href="proyecto.url_sitioWeb" target="_blank" >Descargar</a>
              
            </div>
          </div>
        </div>
        <!--  </div>  -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      proyectos: [
        {
          nombreProyecto: "Portoviejo Previene",
          url_image: "/./img/recursos/imagenes_proyectos/app1.webp",
          descripcion:
            "Entérate de recomendaciones y alertas que emita la Jefatura de Riesgos Portoviejo ante posibles eventos naturales, entre otras funcionalidades.",
          url_sitioWeb: "https://griesgos.portoviejo.gob.ec/",
        },
        {
          nombreProyecto: "My Cognitive Transportation",
          url_image: "/./img/recursos/imagenes_proyectos/app2.webp",
          descripcion:
            "My Cognitive Transportation es un proyecto en construcción para conocer  rutas de buses y negocios de la ciudad de Portoviejo, todo al alcance de la mano en una sola app. Solo tienes que abrir la App y escoger el servicio que desee.",
          url_sitioWeb:
            "https://play.google.com/store/apps/details?id=ec.dowhile.mycognitivetransportation",
          url_repositorio: "https://github.com/Cristhianveal/",
        },
        {
          nombreProyecto: "ServiceXpress",
          url_image: "/./img/recursos/imagenes_proyectos/app3.webp",
          descripcion:
            "ServiceXpress servicios de Delivery con central Portoviejo-Manabí, Realizan entregas a cantones de sus alrededores como: Manta, Rocafuerte, Tosagua, Chone",
          url_sitioWeb:
            "https://play.google.com/store/apps/details?id=ec.dowhile.servicexpress",
          url_repositorio: "https://github.com/Cristhianveal/",
        },
      ],
    };
  },
};
</script>

<style>
.bacground {
  background-color: pink;
}
</style>