<template>
  <br />
  <br />
  <br />
  <br />
  <br />
  <div id="contact" class="container section scrollspy ">
    <h5 class="title">Contacto</h5>

    <h5 class="subtitle">
      Póngase en contacto conmigo si desea que trabajemos juntos.
    </h5>
    <br />
    <br />
    <div class="row">
      <div class="row">
        <div
          class="col m2  itemsHabilidades "
          style="margin-left: 10%"
          v-for="(redSocial, index) in redesSociales"
          :key="index"
        >
          <a class="sinColorHipervinculo " :href="redSocial.link" target="_blank">
            <img
            class="responsive-img"
              height="50"
              width="50"
              :src="redSocial.asset"
              alt="imagen"
              align="center"
            />
            <br />
            <h6 style="text-align: center">{{ redSocial.titulo }}</h6>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      redesSociales: [
        {
          titulo: "Enviarme un correo electrónico",
          asset: "/./img/icons/gmail.svg",
          link: "mailto:cristhianveal@gmail.com",
        },
        {
          titulo: "Whatsapp",
          asset: "/./img/icons/whatsapp.svg",
          link: "https://wa.me/+5930978630130",
        },
        {
          titulo: "Sígueme",
          asset: "/./img/icons/twitter.svg",
          link: "https://twitter.com/CristhianVeAl",
        },
        {
          titulo: "Sígueme",
          asset: "/./img/icons/facebook.svg",
          link: "https://www.facebook.com/ecDoWhile",
        },
        {
          titulo: "Sígueme",
          asset: "/./img/icons/github.svg",
          link: "https://github.com/Cristhianveal",
        },
        {
          titulo: "Contáctame",
          asset: "/./img/icons//linkedin.svg",
          link: "https://www.linkedin.com/in/cristhianveal/",
        },
      ],
    };
  },
};
</script>

<style>
.sinColorHipervinculo {
  text-decoration: none;
  color: black;
}

</style>