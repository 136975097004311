<template>
  <div class="parallax-container">
    <div class="parallax" style="filter: blur(0px)">
      <img :src="encabezadoRifa" />
    </div>

    <div class="container parallax-text">
      <h2 id="texto" class="highlight"></h2>
      <h2 class="col s6 m4">
        <h8 class="highlightGrey">1er. Premio:</h8>
        <b class="highlight">OMEN Gaming Laptop 16"</b>
      </h2>
      <h3 class="col s6 m4">
        <!--  <h8 class="highlightGrey">2do. Premio:</h8> -->
        <!--  <b class="highlight"></b> -->
      </h3>
      <h4 class="col s6 m4">
        <!--   <h8 class="highlightGrey">2do. Premio:</h8>
        <b class="highlight">Mesa Gamer & Silla Gamer</b> -->
      </h4>
      <h5 class="col s6 m4">
        <!--  <h8 class="highlightGrey">4to. Premio:</h8> -->
        <!--   <b class="highlight"></b> -->
      </h5>
    </div>
  </div>
  <div class="section white">
    <div class="row container">
      <div v-show="loadingDataRifa" class="progress">
        <div class="indeterminate"></div>
      </div>
      <div v-show="!loadingDataRifa" class="card">
        <!--  <div class="card-content">
      <p>
        I am a very simple card. I am good at containing small bits of
        information. I am convenient because I require little markup to use
        effectively.
      </p>
    </div> -->

        <div class="card-tabs">
          <ul class="tabs tabs-fixed-width">
            <li class="tab">
              <a class="active" style="color: black" href="#comprar"><strong>Comprar RIFA</strong></a>
            </li>
            <li class="tab">
              <a href="#ticket" style="color: black"><strong>MIS NÚMEROS</strong></a>
            </li>
            <li class="tab">
              <a href="#premios" style="color: black"><strong>PREMIOS</strong></a>
            </li>

            <li class="tab">
              <a href="#preguntas" style="color: black"><strong>Preguntas Frecuentes</strong></a>
            </li>
          </ul>
        </div>
        <div class="white">
          <!-- tab 1 si rifa-->
          <div v-if="!rifaOf" id="comprar">
            <div class="row center" style="background-color: #f6f5f2">
              <span>
                <div style="margin: 0px; padding: 4px; background-color: #f6f5f2">
                  Elige los números que desees para aumentar tus posibilidades
                  de tener suerte, cada número tiene un valor de
                  <h5 class="highlight">💵$3.00</h5>
                  ¡Buena elección!
                </div>

                <div class="row center" style="
                    border: 0px;
                    margin-bottom: 10px;
                    margin-top: 5px;
                    background-color: #f6f5f2;
                  ">
                  <div class="col s4 m4 center center-align">
                    <div class="col s12 m12 center-align center black-text" style="
                        background: #ffeb3b;
                        text-align: center;
                        justify-content: center;
                        border: 0px;
                        height: 45px;
                      ">
                      <strong>Seleccionados</strong>
                    </div>
                  </div>

                  <div class="col s4 m4 center center-align">
                    <div class="col s12 m12 center-align black-text" style="
                        background: #3b5998;
                        border: 0px;
                        height: 45px;
                        border-radius: 2%;
                      ">
                      <strong>Reservado</strong>
                    </div>
                  </div>

                  <div class="col s4 m4 center center-align">
                    <div class="col s12 m12 center-align black-text" style="
                        background: #aad4c6;
                        border: 0px;
                        height: 45px;
                        border-radius: 2%;
                      ">
                      <strong>No disponibles</strong>
                    </div>
                  </div>
                </div>

                <div class="row center" style="
                    border: 0px;
                    margin-left: 4px;
                    margin-right: 4px;
                    margin-bottom: 0px;
                    margin-top: 0px;
                    justify-content: space-between;
                  ">
                  <button class="col s3 m3 btn" @click="prevPage" style="margin-right: auto">
                    <i class="material-icons" style="color: #ffeb3b">chevron_left</i>
                  </button>

                  <!-- Agregamos un espacio en blanco -->
                  <div class="col s6 m6" style="margin-right: auto; margin-left: auto">
                    <a v-show="this.carrito.length != 0" href="#modal1" target="_blank"
                      class="waves-effect waves-light btn pulse modal-trigger"><i class="material-icons left">send
                      </i>Reservar
                    </a>
                  </div>

                  <button @click="nextPage" class="col s3 m3 btn" style="margin-left: auto">
                    <i class="material-icons" style="color: #ffeb3b">chevron_right</i>
                  </button>
                </div>
              </span>
              <h6 class="highlight col s12 m12 black-text" style="padding: 8px; margin-bottom: 0px; border-radius: 0%">
                <strong>TABLA DE NÚMEROS DISPONIBLES🤞</strong>
              </h6>
            </div>

            <div class="table-container">
              <table class="">
                <thead>
                  <!--   <tr>
                    <th colspan="10" style="border: 0px">
                       <ul class="pagination center">
                        <li class="waves-effect" @click="prevPage">
                          <a><i class="material-icons">chevron_left</i></a>
                        </li>
                        <li v-for="page in totalPages" :key="page" :class="{ active: currentPage === page }">
                          <a @click="changePage(page)">{{ page }}</a>
                        </li>
                        <li class="waves-effect" @click="nextPage">
                          <a><i class="material-icons">chevron_right</i></a>
                        </li>
                      </ul> 
                    </th>
                  </tr> -->
                  <!--    <tr>
                    <th style="border: 0px" colspan="10">
                      <h6 class="highlight" style="">
                        <strong>NÚMEROS DISPONIBLES</strong>
                      </h6>
                      <div style="margin: 0px; padding: 0px">
                        Elige los números que desees para aumentar tus
                        posibilidades de tener suerte, cada número tiene un valor
                        de
                        <h5 class="highlight">💵$3.00</h5>
                        ¡Buena elección!
                      </div>
                    </th>
                   
                  </tr> -->
                </thead>
                <tbody>
                  <tr style="" v-for="row in paginatedNumbers" :key="row[0]">
                    <td v-for="number in row" :key="number.id" @click="
                      number.ocupado || number.reservaWeb
                        ? showToast(
                          `número ${number.numeroRifa} ${number.reservaWeb ? 'Reservado.' : 'Vendido.'
                          }`
                        )
                        : addCarrito(number)
                      " :style="{
                        background: number.ocupado
                          ? '#AAD4C6'
                          : isInCart(number)
                            ? '#FFEB3B'
                            : number.reservaWeb
                              ? '#3b5998'
                              : '#ffffff',
                        width: '30px',
                        height: '60px',
                      }">
                      <div class="row center-align" style="">
                        <div class="col s12 m12">
                          <div class="circleT s12 m12" :style="{
                            background: number.ocupado ? 'white' : '#white',
                          }">
                            {{ number.numeroRifa }}
                          </div>
                        </div>

                        <p class="col s12 m12" style="padding: 0px; margin: 0px">
                          <strong> {{ number.nombres ?? "" }} </strong>
                        </p>

                        <div class="tooltip">
                          <a class="col s12 m12 circle responsive-img center btn-flat" style="padding: 0px; margin: 0px"
                            v-if="number.compartido_fb"
                            :href="'https://www.facebook.com/CristhianVeal/posts/pfbid02avscpzCoDcVn4MUFv8RaW7HKBgaHTD5tXs33cDHGwwSRMaAnX4qL8QzT11Ye81RTl'"
                            target="_blank" rel="noopener noreferrer">
                            <i class="material-icons" style="color: #3b5998">facebook</i>
                          </a>
                          <span class="tooltiptext">El compró un número y al compartir el post en
                            facebook ganó este número. ¿Que esperas para
                            ganar?</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row center">
              <div class="center" v-show="this.carrito.length != 0" style="border: 0px">
                <a href="#modal1" target="_blank" class="waves-effect waves-light btn pulse modal-trigger"><i
                    class="material-icons left">send </i>Reservar números</a>
              </div>
              <ul class="pagination center col s12 m12">
                <li class="waves-effect" @click="prevPage">
                  <a><i class="material-icons">chevron_left</i></a>
                </li>
                <li v-for="page in totalPages" :key="page" :class="{ active: currentPage === page }">
                  <a @click="changePage(page)">{{ page }}</a>
                </li>
                <li class="waves-effect" @click="nextPage">
                  <a><i class="material-icons">chevron_right</i></a>
                </li>
              </ul>
            </div>
          </div>
          <!-- tab 1 si rifa-->
          <div v-if="rifaOf" id="comprar">
            <div class="row center" style="background-color: #f6f5f2">
              <b class="mt-2">¡Gracias por tu interés en comprar! La compra ha sido cerrada.</b>

            </div>



          </div>

          <!-- tab ticket -->
          <div id="ticket">
            <div class="row">
              <form class="col s12 m12">
                <div class="row">
                  <div class="input-field col s12 m12">
                    <i class="material-icons prefix">search</i>
                    <textarea id="icon_prefix2" class="materialize-textarea" v-model="codigoReserva" style="
                        resize: none;
                        max-height: 1.2em;
                        overflow-y: hidden;
                      " @keyup.enter="buscarCodigoReserva()"></textarea>
                    <label for="icon_prefix2">Ingrese su código de reserva</label>
                    <span class="helper-text" data-error="wrong" data-success="right">Ingrésalo tal cual respetando las
                      mayúsculas y
                      minúsculas. (Este código te lo envié por whatsapp)</span>
                  </div>
                  <button type="button" class="btn center col s12 m12" @click="buscarCodigoReserva()">
                    Buscar
                  </button>
                </div>
              </form>
            </div>

            <div v-show="infoReserva?.numeros && infoReserva?.numeros.length > 0" class="row">
              <div class="col s12 m12">
                <div class="card">
                  <div class="card-image">
                    <img draggable="false" style="height: 350px" :src="encabezadoRifa" />
                    <span class="card-title black-text"><strong class="">RIFA Geek 🧑‍💻</strong></span>
                  </div>
                  <div class="card-content">
                    <span>
                      <strong class="highlight">{{
                        infoReserva.nombres
                      }}</strong>
                      Tus números asignados con tu código de reserva '<strong class="">{{ infoReserva.codigo_reserva
                        }}</strong>' son:
                    </span>
                    <div class="container">
                      <span>
                        <h5 class="highlight">
                          {{ infoReserva?.numeros?.join(", ") }}
                        </h5>
                        <p>
                          ¡Que la suerte esté de tu lado y que estos números te
                          traigan grandes premios!
                        </p>
                      </span>
                    </div>
                    <br />
                    <div class="">
                      <span v-show="infoReserva.numeroFB != null &&
                        infoReserva.numeroFB != ''
                        ">
                        <p>
                          Gracias por compartir y por ello tu número escogido
                          gratis fue el
                          <strong>{{ infoReserva.numeroFB }}</strong>.
                        </p>
                      </span>
                      <br />
                      <span>
                        <p>
                          En caso de ser el afortunado ganador serás contactado
                          vía whatsapp a su número
                          <strong>{{ infoReserva.telefono }}</strong>.
                        </p>
                      </span>
                    </div>
                  </div>

                  <div class="card-action">
                    <ul>
                      <li>
                        <span><strong>Contacto</strong>:
                          <a :href="getWhatsAppLink('¡Hola Cristhian!')" target="_blank">0978630130</a></span>
                      </li>
                      <li>
                        <span><strong>Perfil de transmición:</strong>
                          <a href="https://www.facebook.com/cristhianveal" target="_blank">CristhianVeAl</a></span>
                      </li>
                      <li>
                        <span><strong>Canal de Whatsapp:</strong>
                          <a href="https://whatsapp.com/channel/0029VacKyN0JENxv4oCg4W3L" target="_blank">Rifa Geek
                            👨‍💻
                          </a></span>
                      </li>
                    </ul>
                    <div class="center">
                      <p>
                        ¡Descárgalo o realiza una captura para tener constancia
                        de tu compra! Con este ticket puedes reclamar tus
                        premios.
                      </p>
                      <!-- <a href="#">Descargar mi ticket</a> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- tab 2 -->
          <div id="premios">
            <ul class="collapsible">
              <li>
                <div class="collapsible-header">
                  <img draggable="false" :src="CPUAsset" width="60" height="50" /><strong>LAPTOP Gamer</strong>
                </div>
                <div class="collapsible-body">
                  <div class="card-image">
                    <p> <b>OMEN Gaming Laptop de 16"</b> 💻 con procesador Intel Core i7 de 9na. generación, 8GB de RAM
                      y 250GB de disco sólido y tarjeta gráfica Nvidia. ¡Prepárate para ganar!</p>
                    <img class="responsive-img" draggable="false" :src="infoLaptopAsset" />
                  </div>
                </div>
              </li>

              <!--    <li>
                <div class="collapsible-header">
                  <img
                    draggable="false"
                    :src="CPUAsset"
                    width="60"
                    height="50"
                  /><strong>CPU Gamer</strong>
                </div>
                <div class="collapsible-body">
                  <div class="card-image">
                    <img draggable="false" :src="infoCPUAsset" class="" />
                  </div>
                </div>
              </li> -->

              <!--    <li>
                <div class="collapsible-header">
                  <img
                    draggable="false"
                    :src="MonitorAsset"
                    width="60"
                    height="50"
                  /><strong>Monitor Curvo</strong>
                </div>
                <div class="collapsible-body">
                  <div class="card-image">
                    <img draggable="false" :src="infoMonitorAsset" class="" />
                  </div>
                </div>
              </li>

              <li>
                <div class="collapsible-header">
                  <img
                    draggable="false"
                    :src="MesaAsset"
                    width="60"
                    height="50"
                  /><strong>Mesa Gamer</strong>
                </div>
                <div class="collapsible-body">
                  <div class="card-image">
                    <img draggable="false" :src="infoMesaAsset" class="" />
                  </div>
                </div>
              </li>

              <li>
                <div class="collapsible-header">
                  <img
                    draggable="false"
                    :src="SillaAsset"
                    width="60"
                    height="50"
                  /><strong>Silla Gamer</strong>
                </div>
                <div class="collapsible-body">
                  <div class="card-image">
                    <img draggable="false" :src="infoSillaAsset" class="" />
                  </div>
                </div>
              </li> -->
            </ul>
          </div>
          <!-- tab 3 -->
          <div id="preguntas">
            <ul class="collapsible">
              <li>
                <div class="collapsible-header">
                  <img draggable="false" :src="chicoGamerAsset" width="60" height="50" /><strong>¿Cuáles son los premios
                    que se están rifando en la rifa
                    tecnológica?</strong>
                </div>
                <div class="collapsible-body">
                  En la rifa Geek tecnológica se están rifando 1 premios: Una Laptop Gamer,
                  puedes ver mas información de los premios en la sección de
                  <a>Premios</a>.
                </div>
              </li>

              <li>
                <div class="collapsible-header">
                  <img draggable="false" :src="chicoGamerAsset" width="60" height="50" /><strong>¿Cuál es el precio por
                    participar en la rifa?</strong>
                </div>
                <div class="collapsible-body">
                  El precio por participar en la rifa es de
                  <strong>$3</strong> por número. ¡Recuerda que entre más
                  números compres, aumentan tus posibilidades de ganar! 😁
                </div>
              </li>

              <li>
                <div class="collapsible-header">
                  <img draggable="false" :src="chicoGamerAsset" width="60" height="50" /><strong>¿Cómo se determina el
                    ganador de la rifa?</strong>
                </div>
                <div class="collapsible-body">
                  El ganador de la rifa se determina mediante un sorteo que se
                  llevará a cabo una vez que se vendan el 80% de los números
                  disponibles. Se notificará a los participantes cuando se
                  realice el sorteo, el medio de canal de anuncio de la fecha
                  será en este sitio web y vía whatsapp.
                </div>
              </li>

              <li>
                <div class="collapsible-header">
                  <img draggable="false" :src="chicoGamerAsset" width="60" height="50" /><strong>¿Puedo ganar más de un
                    premio con el mismo número?</strong>
                </div>
                <div class="collapsible-body">
                  Sí, ¡tienes la oportunidad de ganar uno de los 4 premios con
                  cada número que compres para participar en la rifa! Además,
                  entre más números adquieras, aumentas tus posibilidades de
                  llevarte el Setup completo. 😁😁
                </div>
              </li>

              <li>
                <div class="collapsible-header">
                  <img draggable="false" :src="chicoGamerAsset" width="60" height="50" /><strong>¿Cuál es la fecha del
                    sorteo de la rifa?</strong>
                </div>
                <div class="collapsible-body">
                  La fecha del sorteo de la rifa será anunciada una vez que se
                  vendan almenos el 80% de los números disponibles. Los
                  participantes serán notificados con anticipación vía whatsapp
                  y en mi red social
                  <a href="https://www.facebook.com/cristhianveal" target="_blank">
                    Cristhian Vera Almeida</a>
                </div>
              </li>

              <li>
                <div class="collapsible-header">
                  <img draggable="false" :src="chicoGamerAsset" width="60" height="50" /><strong>¿Dónde puedo comprar
                    los números para participar en la
                    rifa?</strong>
                </div>
                <div class="collapsible-body">
                  Los números para participar en la rifa pueden ser adquiridos
                  vía whatsapp a mi número
                  <a :href="getWhatsAppLink()" target="_blank">0978630130</a> y
                  una vez cancelado vía deposito o en efectivo se te asignará un
                  <strong> código de reserva</strong> que contiene tus números
                  de rifas comprados. que podran ser visualizado en el sitio web
                  con las iniciales de sus nombres.
                </div>
              </li>
              <!-- <li>
                <div class="collapsible-header">
                  <img
                    draggable="false"
                    :src="chicoGamerAsset"
                    width="60"
                    height="50"
                  /><strong>
                    ¿Por qué decides rifar esta potente PC gamer?</strong
                  >
                </div>
                <div class="collapsible-body">
                  Decidí rifar esta potente PC gamer debido a razones
                  personales. Aunque ha sido mi fiel compañera durante un año,
                  he decidido dejar de utilizarla. Durante este tiempo, la PC
                  gamer ha demostrado su excelente desempeño, permitiéndome
                  realizar trabajos de calidad y disfrutar de videojuegos sin
                  problemas gracias a sus potentes componentes.
                </div>
              </li> -->
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-show="!loadingDataRifa" class="parallax-container">
    <div class="parallax">
      <img style="filter: blur(1.5px)" :src="footerRifa" />
    </div>
    <div class="container parallax-text" style="">
      <h5 v-if="!rifaOf" style="color: black; text-align: center">
        <b class="highlight" style="color: black">¡Participa en nuestra rifa y ayúdanos a alcanzar nuestro objetivo!</b>
      </h5>
      <h5 v-if="!rifaOf" style="color: black; text-align: center">
        No hay fecha límite, el sorteo se realizará una vez se vendan el 80% de
        los números números disponibles. ¡Compra tu número ahora y sé parte de
        esta increíble oportunidad!
        <a :href="getWhatsAppLink()" target="_blank">Comprar</a>
      </h5>
      <h4 v-if="rifaOf" style="color: black; text-align: center">
        Ver sorteo en
        <a href="https://www.facebook.com/cristhianveal" target="_blank" rel="noopener noreferrer"><b class="blue"
            style="color: white"> Facebook</b><b class="red" style="color: white">Live</b></a>
      </h4>
      <h6 v-if="rifaOf" style="color: black; text-align: center">
        <a style="text-align: center" href="https://www.facebook.com/cristhianveal"
          target="__blank">https://www.facebook.com/cristhianveal</a>
      </h6>
    </div>
  </div>

  <div v-show="this.carrito.length != 0" class="fixed-action-btn">
    <a id="menu" class="btn-floating pulse btn-large modal-trigger waves-light waves-effect" href="#modal1">
      <i class="large material-icons" style="color: white">send</i>
    </a>


    <!-- Modal Structure -->
    <div id="modal1" class="modal bottom-sheet">
      <div class="modal-content">
        <h4>
          Reservar números de rifas: <b> {{ getSelectedNumbers() }}.</b>
        </h4>
        <!-- <p>Gracias por apoyar con tu compra de los números seleccionados.</p> -->
        <p>
          <strong class="highlight">Tienes dos formar de reservar tus números</strong>
          registrandolo manualmente ingresandos tus nombres y número de whatsapp
          (lo reservas directamente, te recomiendo por esta opción. ) Ó dando
          clic en el siguiente
          <a :href="getWhatsAppLink()" target="_blank">enlace</a> para
          redireccionarlo a mi WhatsApp y así ayudarte con la separación.
        </p>
        <div class="row" style="width: 100%">
          <a href="#modalFormulario"
            class="waves-effect waves-green btn-flat modal-trigger col m6 s12 center center-align pulse"
            style="background: #4793af">
            <i class="large material-icons">shopping_cart</i>
            <strong>Reservar directamente con mis datos</strong>
          </a>
          <h5 href="" class="modal-close waves-effect waves-green btn-flat center center-align col m2 s12">
            <strong>Reservar otros</strong>
          </h5>

          <a :href="getWhatsAppLink()" target="_blank"
            class="modal-close waves-effect waves-green btn-flat white-text col m4 s12 center center-align"
            style="background: #25d366">
            <i class="large material-icons">shopping_cart</i> Reservar vía
            whatsapp
          </a>
        </div>
      </div>
      <div class="modal-footer">
        <!-- Enlace para redireccionar a WhatsApp -->
      </div>
    </div>

    <!-- Modal formulario  -->
    <!-- Modal Trigger -->

    <!-- Modal Structure -->
    <div id="modalFormulario" class="modal modal-fixed-footer">
      <div class="modal-content">
        <h4>Reservar</h4>
        <p>
          Usted reserverá los números
          <strong>{{ getSelectedNumbers() }}</strong> , Por favor registre sus
          nombres y número de teléfono. una vez enviado me pongo en contacto con
          usted para hacer la reserva oficial.
        </p>
        <div class="row">
          <form class="col s12">
            <div class="row">
              <div class="input-field col s12 m6">
                <i class="material-icons prefix">account_circle</i>
                <input id="icon_prefix" type="text" autocomplete="off" v-model="formularioReserva.nombres"
                  class="validate" onpaste="return false;" pattern="[A-Za-z ]+"
                  title="Solo se permiten letras y espacios" />
                <label for="icon_prefix">Nombres y Apellidos Completos</label>
              </div>
              <div class="input-field col s12 m6">
                <i class="material-icons prefix">phone</i>
                <input id="icon_telephone" autocomplete="off" maxlength="10" v-model="formularioReserva.telefono"
                  type="tel" class="validate" pattern="[0-9]*" onpaste="return false;" />
                <label for="icon_telephone">Teléfono (Whatsapp)</label>
              </div>

              <div class="input-field col s12 m12">
                <i class="material-icons prefix">description</i>
                <input id="icon_description" type="text" maxlength="900" autocomplete="off"
                  v-model="formularioReserva.observacion" class="validate" onpaste="return false;"
                  pattern="[A-Za-z ]+" />
                <label for="icon_description">¿Tienes alguna observación o algo que me tengas que
                  decir?</label>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="modal-footer">
        <div v-show="registrandoReserva" class="progress">
          <div class="indeterminate"></div>
        </div>
        <a v-show="!registrandoReserva" href="#!" class="modal-close waves-effect waves-green btn-flat">Cancelar</a>
        <a v-show="!registrandoReserva" href="#!" @click="registrarReserva()"
          class="waves-effect waves-green btn pulse">Enviar</a>
      </div>
    </div>
  </div>

  <!-- Tap Target Structure -->
  <!-- <div class="tap-target" data-target="menu">
    <div class="tap-target-content">
      <h5>¡Haz clic aquí para más información!</h5>
      <p>Este es un ejemplo de un elemento de destino táctil.</p>
    </div>
  </div> -->
  <!-- Modal Structure -->
  <div id="modalInfoFechaSorteo" class="modal bottom-sheet">
    <div class="modal-content">
      <h4>
        Reservar números de rifas: <b> xx.</b>
      </h4>
      <!-- <p>Gracias por apoyar con tu compra de los números seleccionados.</p> -->
      <p>
        asdf
      </p>
      <div class="row" style="width: 100%">
        <a href="#modalFormulario"
          class="waves-effect waves-green btn-flat modal-trigger col m6 s12 center center-align pulse"
          style="background: #4793af">
          <i class="large material-icons">shopping_cart</i>
          <strong>Reservar directamente con mis datos</strong>
        </a>
        <h5 href="" class="modal-close waves-effect waves-green btn-flat center center-align col m2 s12">
          <strong>Reservar otros</strong>
        </h5>

        <a :href="getWhatsAppLink()" target="_blank"
          class="modal-close waves-effect waves-green btn-flat white-text col m4 s12 center center-align"
          style="background: #25d366">
          <i class="large material-icons">shopping_cart</i> Reservar vía
          whatsapp
        </a>
      </div>
    </div>
    <div class="modal-footer">
      <!-- Enlace para redireccionar a WhatsApp -->
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      // numbers: Array.from({ length: 1000 }, (_, i) => i + 1),
      numbers: [],
      rifaOf: true,
      loadingDataRifa: false,
      registrandoReserva: false,
      codigoReserva: "",
      carrito: [],
      endpointRifa: "https://griesgos.portoviejo.gob.ec/apis/rifa/rifa.php",
      endpointBuscarReserva:
        "https://griesgos.portoviejo.gob.ec/apis/rifa/reservaPorCodigo.php",
      endpointIngresarReserva:
        "https://griesgos.portoviejo.gob.ec/apis/rifa/updateReserva.php",
      //endpointIngresarReserva: "localhost/GIZAPP/BACKEND_GIZ/api_app_mobile/apis/rifa/updateReserva.php",
      infoReserva: {},
      formularioReserva: {},
      currentPage: 1,
      perPage: 100,
      headers: Array.from({ length: 10 }, (_, i) => `Número ${i + 1}`),
      encabezadoRifa: "/./img/recursos/rifa/encabezado_rifa.svg",
      footerRifa: "/./img/recursos/rifa/footer_rifa.svg",
      CPUAsset: "/./img/recursos/rifa/cpu.svg",
      infoCPUAsset: "/./img/recursos/rifa/infoCPU.svg",
      infoLaptopAsset: "/./img/recursos/rifa/LAPTOP_GAMER.jpeg",
      MonitorAsset: "/./img/recursos/rifa/monitor.svg",
      infoMonitorAsset: "/./img/recursos/rifa/infoMonitor.svg",
      MesaAsset: "/./img/recursos/rifa/mesa.svg",
      infoMesaAsset: "/./img/recursos/rifa/infoMesa.svg",
      SillaAsset: "/./img/recursos/rifa/silla.svg",
      infoSillaAsset: "/./img/recursos/rifa/infoSilla.svg",
      chicoGamerAsset: "/./img/recursos/rifa/chicoGamer.svg",
      text: "RIFA Geek 🧑‍💻",
      speed: 100,
      i: 0,
    };
  },
  computed: {
    paginatedNumbers() {
      const startIndex = (this.currentPage - 1) * this.perPage;
      return this.numbers
        .slice(startIndex, startIndex + this.perPage)
        .reduce(
          (rows, key, index) =>
            (index % 10 == 0
              ? rows.push([key])
              : rows[rows.length - 1].push(key)) && rows,
          []
        );
    },
    totalPages() {
      return Math.ceil(this.numbers.length / this.perPage);
    },
  },
  mounted() {
    // Llamar al método para cargar los datos cuando el componente se monte

    if (!this.rifaOf) this.fetchNumbers()
    this.typeWriter();
  },
  methods: {
    infoEnviar() {
      // Inicializar el elemento de destino táctil
      const elem = document.querySelector(".tap-target");
      const instance = M.TapTarget.init(elem);
      instance.open();
    },
    async fetchNumbers(loadingGeneral) {
      try {
        let numerosRifas = [];
        if (!loadingGeneral) {
          this.loadingDataRifa = true;
        }
        const response = await axios.get(this.endpointRifa);
        this.loadingDataRifa = false;
        if (response.data.status === "ok" && response.data.code === "200") {
          const data = response.data.data;
          const listaFiltrada = data.filter((item) => !item.ocupado);

          const numerosRifas = listaFiltrada.map((item) => ({
            id: item.id,
            numeroRifa: item.numero.toString().padStart(2, "0"),
            nombres: item.nombres,
            ocupado: item.ocupado === 1 ? true : false,
            reservaWeb: item.reservaWeb === 1 ? true : false,
            compartido_fb: item.compartido_fb === 1 ? true : false,
            //iconReserva: 'https://xsgames.co/randomusers/avatar.php?-g=pixel'
            //iconReserva: `https://robohash.org/stefan-${item.nombres}`
            iconReserva: `https://ui-avatars.com/api/?name=${item.nombres}`,
          }));
          this.numbers = numerosRifas;
        } else {
          throw new Error(response.data.message);
        }
      } catch (error) {
        this.loadingDataRifa = false;
        alert(
          error +
          "Si el error persiste, favor contactar al administrador Cristhian Vera, 0978630130 avisando por vía whatsapp 🙏🙏🙏"
        );
      }
    },
    contieneSoloNumeros(cadena) {
      return /^\d+$/.test(cadena);
    },
    async registrarReserva() {
      try {
        if (
          this.formularioReserva?.nombres != "" &&
          this.formularioReserva?.telefono?.length == 10 &&
          this.contieneSoloNumeros(this.formularioReserva?.telefono)
        ) {
          let numerosTemp = [];
          this.carrito.forEach((element) => {
            numerosTemp.push(element.numeroRifa);
          });
          // Construir los parámetros
          let parametros = new URLSearchParams();
          parametros.append("numeros", JSON.stringify(numerosTemp));
          parametros.append("nombres", this.formularioReserva.nombres);
          parametros.append("telefono", this.formularioReserva.telefono);
          parametros.append("observacion", this.formularioReserva.observacion);

          // Construir la URL con los parámetros
          let url = `${this.endpointIngresarReserva}?${parametros.toString()}`;
          this.registrandoReserva = true;
          const response = await axios.get(url);
          this.registrandoReserva = false;
          // const response = await axios.put(this.endpointIngresarReserva);
          //console.log(response)
          if (response.data.status === "ok" && response.data.code === "200") {
            alert(
              "Gracias por reservar tus números, pronto me contacto con usted."
            );
            window.location.reload();
          } else {
            throw new Error(response.data.message);
          }
        } else {
          this.showToast("Ingrese sus nombres y teléfono de 10 dígitos.");
        }
      } catch (error) {
        this.registrandoReserva = false;
        alert(
          error +
          "Si el error persiste, favor contactar al administrador Cristhian Vera, 0978630130 avisando por vía whatsapp 🙏🙏🙏"
        );
      }
    },

    async buscarCodigoReserva() {
      try {
        if (this.codigoReserva != "" && this.codigoReserva.length > 0) {
          this.infoReserva = {};
          let infoReservaTemp = {};
          //let codigoReserva = "Jrobles"
          const response = await axios.get(
            this.endpointBuscarReserva +
            `?codigoReserva=${this.codigoReserva?.trim()}`
          );

          if (response.data.status === "ok" && response.data.code === "200") {
            const data = response.data.data;
            if (data.numeros != 0) {
              infoReservaTemp = {
                nombres: data.nombres,
                numeros: data.numeros,
                codigo_reserva: data.codigo_reserva,
                telefono: data.telefono,
                compartido_fb: data.compartido_fb,
                observacion: data.observacion,
                numeroFB: data.numeroFB,
              };
              this.infoReserva = infoReservaTemp;
            } else {
              this.showToast(
                "No se encontraron números reservados con el código de reserva proporcionado. Si crees que ha ocurrido un error, verifica tu código o contáctanos para obtener ayuda."
              );
            }
          } else {
            throw new Error(response.data.message);
          }
        } else {
          this.showToast("Ingrese su código de reserva.");
        }
      } catch (error) {
        alert(
          error +
          "Si el error persiste, favor contactar al administrador Cristhian Vera, 0978630130 avisando por vía whatsapp 🙏🙏🙏"
        );
      }
    },
    getSelectedNumbers() {
      return this.carrito
        .map((item) => item.numeroRifa)
        .sort((a, b) => a - b) // Ordena los números en orden ascendente
        .join(", ");
    },
    getWhatsAppLink(custoMSG) {
      const selectedNumbers = this.getSelectedNumbers();
      let message = `¡Hola! Estoy interesado en comprar los siguientes números de rifas: ${selectedNumbers}. Te saluda, SusNombres.`;
      if (custoMSG) {
        message = custoMSG;
      }
      const whatsappNumber = "593978630130"; // Reemplaza 'TU_NUMERO' con tu número de WhatsApp
      return `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(
        message
      )}`;
    },
    clearCarrito() { },
    showToast(mensaje) {
      M.Toast.dismissAll();

      var toastHTML = `<span>${mensaje}</span><button class="btn-flat toast-action">OK</button>`;
      M.toast({ html: toastHTML });
    },
    async addCarrito(number) {
      try {
        M.Toast.dismissAll();
        // await this.fetchNumbers(true);
        /* if (this.carrito.length == 0) {
          this.infoEnviar();
        } */
        const index = this.carrito.findIndex((item) => item.id === number.id);
        if (index !== -1) {
          // Si el número ya está en el carrito, quitarlo
          this.carrito.splice(index, 1);
        } else {
          // Si el número no está en el carrito, agregarlo
          this.carrito.push(number);
        }
      } catch (error) {
        alert("error: add carrito" + error);
      }
    },
    isInCart(number) {
      // Verificar si el número está en el carrito
      return this.carrito.some((item) => item.id === number.id);
    },
    changePage(page) {
      this.currentPage = page;
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    typeWriter() {
      if (this.i < this.text.length) {
        document.getElementById("texto").innerHTML += this.text.charAt(this.i);
        this.i++;
        setTimeout(this.typeWriter, this.speed);
      }
    },
  },
};
document.addEventListener("DOMContentLoaded", function () {
  var elems = document.querySelectorAll(".fixed-action-btn");
});
</script>

<style>
.custom-table th,
td {
  border-color: #ffde59 !important;
  border: 1px solid #ffde59;
  background-color: white;
}

.parallax-text {
  position: absolute;
  top: 45%;
  left: 47%;
  transform: translate(-50%, -50%);
  color: white;
}

.highlight {
  background-color: #ffd700;
  /* Cambia el color de fondo según tus preferencias */
  padding: 0 5px;
  /* Ajusta el espacio alrededor del texto según tus preferencias */
  display: inline-block;
  animation: blink-caret 0.75s step-end infinite;
}

.highlightGrey {
  background-color: #aad4c6;
  /* Cambia el color de fondo según tus preferencias */
  padding: 0 5px;
  color: rgb(36, 34, 34);
  font-weight: bold;
  /* Ajusta el espacio alrededor del texto según tus preferencias */
  display: inline-block;
}

.card-tabs.green .tabs .tab a.active {
  border-bottom: 2px solid green;
  /* Cambia el color a verde */
}

.circleT {
  width: 25px;
  height: 25px;
  border-radius: 25%;
  background-color: #ffffff;
  /* Color de fondo del círculo */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  /* Tamaño del carácter */
  color: #414141;
  /* Color del carácter */
  text-align: center;
  align-content: center;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted #3b5998;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #3b5998;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

/* Additional CSS styles to ensure proper display on mobile */
.table-container {
  overflow-x: auto;
}

.responsive-table {
  width: 100%;
  white-space: nowrap;
  /* Evita que los elementos se envuelvan */
}
</style>